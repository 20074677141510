// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: "DEV",
  production: false,
  msalConfig: {
      auth: {
        clientId: 'd13b1353-08be-49aa-875c-32f795a7abe5',
        authority: 'https://login.microsoftonline.com/209c23ac-3a82-426d-b118-fe712ac7d598'
  }
  },
  graphConfig: {
        scopes: ['user.read'],
        uri: 'https://graph.microsoft-ppe.com/v1.0/me'
  },
  apiConfig: {
      baseUrl: 'https://func-desktopattendant-dev-01.azurewebsites.net',
      scopes: ['api://desktopattendant/desktopattendant.device.register'],
      uri: 'api://desktopattendant'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
